import agri from "../../assets/images/visuals/agri.jpg"
import { Button } from "../ui/button"

export default function FullCard(props:any) {

  return (
    <div className="flex flex-col lg:flex-row justify-between items-center p-10 lg:py-20 lg:px-16 bg-primary-lighter rounded-xl">
      <div className="flex-1">
        <h5 className="lg:mr-10 text-lg md:text-xl text-accent">Île-de-France</h5>
        <h4 className="lg:mr-10 text-3xl md:text-4xl font-bold text-secondary-dark my-2">La première place de marché ultra locale</h4>
        <p className="lg:mr-10 text-lg mb-4">
          Dans la belle campagne de Seine-et-Marne, à quelques dizaines de kilomètres de Paris, quelques producteurs se sont rassemblés pour proposer des produits locaux et de qualité.
        </p>
        <div className="flex">
          {props.buyer ?
            <Button 
              variant="destructive" 
              className="mr-4" 
              href="https://appro.mullo.fr/"
            >
              Consulter
            </Button>
          : 
          <>
            <Button 
              variant="secondary" 
              className="mr-4" 
              href="https://appro.mullo.fr/inscription"
            >
              Rejoindre
            </Button>
            <Button variant="destructive" href="https://forms.fillout.com/t/v9auhK6gFYus?type=mulloNew">Créer</Button>
          </>
          }
        </div>
      </div>
      <div className="flex-1 mt-10 lg:mt-0">
        <img
          src={agri}
          alt="Place de marché"
          className="rounded-xl"
        />
      </div>
    </div>
  )
}